<template>
  <v-container fluid class="col-lg-10" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Terms & Conditions
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          depressed
          color="cyan white--text"
          class="custom-bold-button mr-3"
          v-on:click="addTerms()"
        >
          <v-icon small left>mdi-plus</v-icon>
          Add
        </v-btn>
        <v-btn
          depressed
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card
      flat
      class="custom-setting-height custom-grey-border remove-border-radius"
    >
      <v-card-title class="headline grey lighten-4">
        <v-row style="overflow: auto">
          <v-col md="12">
            <ListingTable
              :columnCount="6"
              :dataLoading="pageLoading"
              :rowData="terms"
            >
              <template v-slot:thead>
                <thead>
                  <tr>
                    <th class="simple-table-th" width="5">#</th>
                    <th class="simple-table-th" width="10">Default</th>
                    <th class="simple-table-th" width="10">Module</th>
                    <th class="simple-table-th">Title</th>
                    <th class="simple-table-th">Description</th>
                    <!-- <th class="simple-table-th">Delivery</th>
                    <th class="simple-table-th">Warranty</th>
                    <th class="simple-table-th">Payment</th> -->
                    <th class="simple-table-th" width="10">Status</th>
                  </tr>
                </thead>
              </template>
              <template v-slot:tbody>
                <tbody class="custom-border-bottom">
                  <template v-if="terms.length">
                    <tr v-for="(row, index) in terms" :key="index" link>
                      <td
                        v-on:click="updateTerms(row)"
                        class="simple-table-td"
                        width="5"
                      >
                        {{ index + 1 }}.
                      </td>
                      <td
                        width="10"
                        class="cursor-default simple-table-td text-center"
                        align="center"
                      >
                        <v-switch
                          class="my-2 p-0"
                          color="cyan"
                          v-model="row.default"
                          inset
                          hide-details
                          :readonly="
                            statusLoading || !row.status || row.default
                          "
                          v-on:change="updateDefault(row.id)"
                        ></v-switch>
                      </td>
                      <td v-on:click="updateTerms(row)" class="simple-table-td">
                        <span v-if="row.module">{{ row.module }}</span>
                        <em class="text-muted" v-else> no module</em>
                      </td>
                      <td v-on:click="updateTerms(row)" class="simple-table-td">
                        <span v-if="row.title">{{ row.title }}</span>
                        <em class="text-muted" v-else> no title</em>
                      </td>
                      <td v-on:click="updateTerms(row)" class="simple-table-td">
                        <span v-html="row.validity" v-if="row.validity"></span>
                        <em class="text-muted" v-else> no Description</em>
                      </td>
                      <!-- <td v-on:click="updateTerms(row)" class="simple-table-td">
                        {{ row.delivery }}
                      </td>
                      <td v-on:click="updateTerms(row)" class="simple-table-td">
                        {{ row.warranty }}
                      </td>
                      <td v-on:click="updateTerms(row)" class="simple-table-td">
                        {{ row.payment }}
                      </td> -->
                      <td
                        class="cursor-default simple-table-td text-center"
                        align="center"
                        width="10"
                      >
                        <v-switch
                          class="my-2 p-0"
                          color="cyan"
                          v-model="row.status"
                          inset
                          hide-details
                          :readonly="row.default"
                          :disabled="statusLoading"
                          v-on:change="updateStatus(row.id)"
                        ></v-switch>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td :colspan="6" class="py-4">
                      <p
                        class="m-0 row-not-found text-center font-weight-500 font-size-16"
                      >
                        <img
                          :src="$assetURL('media/error/empty.png')"
                          class="row-not-found-image"
                        />
                        Uhh... There are no entity at the moment.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </ListingTable>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <Dialog :common-dialog="terms_dialog" :dialogWidth="dialogWidth">
      <template v-slot:title>
        <span v-if="entity.id">Update</span><span v-else>New</span>&nbsp;Terms &
        Condition
      </template>
      <template v-slot:body>
        <v-container>
          <v-form
            ref="termsForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="onSubmit()"
          >
            <v-layout>
              <v-flex md6>
                <label
                  for="terms-title"
                  class="font-size-16 font-weight-500 required"
                >
                  Title</label
                >
                <v-text-field
                  dense
                  filled
                  label="Title"
                  solo
                  flat
                  color="cyan"
                  v-model="termsData.title"
                  :rules="[validateRules.required(termsData.title, 'Title')]"
                ></v-text-field>
              </v-flex>
              <v-flex md6>
                <label
                  for="terms-title"
                  class="font-size-16 font-weight-500 required"
                >
                  Module</label
                >
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  id="sales-representatives"
                  :items="allModlue"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-model="termsData.module"
                  label="Module"
                  :rules="[validateRules.required(termsData.module, 'Module')]"
                  solo
                  flat
                  item-color="cyan"
                  item-text="text"
                  item-value="value"
                  hide-details=""
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="'No Module Found.'"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <!-- <v-autocomplete
                  v-model="termsData.module"
                  :items="allModlue"
                  
                  dense
                  placeholder="Module"
                  clearable
                  item-color="success"
                  :rules="[validateRules.required(termsData.module, 'Module')]"
                ></v-autocomplete> -->
                <!--  <v-text-field
                  dense
                  filled
                  label="Title"
                  solo
                  flat
                  color="cyan"
                  v-model="termsData.title"
                  :rules="[validateRules.required(termsData.title, 'Title')]"
                ></v-text-field> -->
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <label
                  for="terms-validity"
                  class="font-size-16 font-weight-500 required"
                >
                  Description</label
                >
                <editor v-model="termsData.validity" />
                <!-- <v-textarea
                  v-model.trim="termsData.validity"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Validity"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(termsData.validity, 'Validity', 1),
                    validateRules.maxLength(
                      termsData.validity,
                      'Validity',
                      1024
                    ),
                  ]"
                  row-height="25"
                ></v-textarea> -->
              </v-flex>
              <!-- <v-flex >
                <label
                  for="terms-gst-charges"
                  class="font-size-16 font-weight-500 required"
                  >GST Charges</label
                >
                <v-textarea
                    v-model.trim="termsData.gst_charges"
                    auto-grow
                    dense
                    filled
                    :disabled="formLoading"
                    :loading="formLoading"
                    color="cyan"
                    label="GST Charges"
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        termsData.gst_charges,
                        'GST Charges',
                        1
                      ),
                      validateRules.maxLength(
                        termsData.gst_charges,
                        'GST Charges',
                        1024
                      ),
                    ]"
                    row-height="25"
                  ></v-textarea>
                
              </v-flex> -->
              <!-- <v-flex>
                <label
                  for="terms-payment"
                  class="font-size-16 font-weight-500 required"
                >
                  Payment</label
                >
                <v-textarea
                  v-model.trim="termsData.payment"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Payment"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(termsData.payment, 'Payment', 1),
                    validateRules.maxLength(termsData.payment, 'Payment', 1024),
                  ]"
                  row-height="25"
                ></v-textarea>
              </v-flex>
            </v-layout>

            <v-layout>
              <v-flex>
                <label
                  for="terms-delivery"
                  class="font-size-16 font-weight-500 required"
                >
                  Delivery</label
                >
                <v-textarea
                  v-model.trim="termsData.delivery"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Delivery"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(termsData.delivery, 'Delivery', 1),
                    validateRules.maxLength(
                      termsData.delivery,
                      'Delivery',
                      1024
                    ),
                  ]"
                  row-height="25"
                ></v-textarea>
              </v-flex>
              <v-flex>
                <label
                  for="terms-deposit"
                  class="font-size-16 font-weight-500 required"
                >
                  Deposit</label
                >
                <v-textarea
                  v-model.trim="termsData.deposit"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Deposit"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(termsData.deposit, 'Deposit', 1),
                    validateRules.maxLength(termsData.deposit, 'Deposit', 1024),
                  ]"
                  row-height="25"
                ></v-textarea>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex>
                <label
                  for="terms-warranty"
                  class="font-size-16 font-weight-500 required"
                >
                  Warranty</label
                >
                <v-textarea
                  v-model.trim="termsData.warranty"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Warranty"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(termsData.warranty, 'Warranty', 1),
                    validateRules.maxLength(
                      termsData.warranty,
                      'Warranty',
                      1024
                    ),
                  ]"
                  row-height="25"
                ></v-textarea>
              </v-flex>
              <v-flex>
                <label
                  for="terms-cancellation"
                  class="font-size-16 font-weight-500 required"
                >
                  Cancellation</label
                >
                <v-textarea
                  v-model.trim="termsData.cancellation"
                  auto-grow
                  dense
                  filled
                  :disabled="formLoading"
                  :loading="formLoading"
                  color="cyan"
                  label="Cancellation"
                  solo
                  flat
                  :rules="[
                    validateRules.minLength(
                      termsData.cancellation,
                      'Cancellation',
                      1
                    ),
                    validateRules.maxLength(
                      termsData.cancellation,
                      'Cancellation',
                      1024
                    ),
                  ]"
                  row-height="25"
                ></v-textarea>
              </v-flex> -->
            </v-layout>
            <!-- <v-layout>
              
              <v-flex >
                <label
                  for="terms-stock-availability"
                  class="font-size-16 font-weight-500 required"
                  >Stock Availability</label
                >
                <v-textarea
                    v-model.trim="termsData.stock_availability"
                    auto-grow
                    dense
                    filled
                    :disabled="formLoading"
                    :loading="formLoading"
                    color="cyan"
                    label="Stock Availability"
                    solo
                    flat
                    :rules="[
                      validateRules.minLength(
                        termsData.stock_availability,
                        'Stock Availability',
                        1
                      ),
                      validateRules.maxLength(
                        termsData.stock_availability,
                        'Stock Availability',
                        1024
                      ),
                    ]"
                    row-height="25"
                  ></v-textarea>
                
              </v-flex>
            </v-layout> -->
          </v-form>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="onSubmit()"
          >Update
        </v-btn>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="terms_dialog = false"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { GET, POST, PATCH } from "@/core/services/store/request.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
//import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { setCookie, getCookie } from "@/core/plugins/bt-cookie";

export default {
  mixins: [ValidationMixin],
  name: "profile-company-setting",
  data() {
    return {
      formValid: true,
      statusLoading: false,
      pageLoading: true,
      formLoading: false,
      terms_dialog: false,
      entities: [],
      terms: [],
      allModlue: [
        { value: "quotation", text: "Quotation" },
        { value: "contract", text: "Contract" },
        { value: "invoice", text: "Invoice" },
        { value: "visit", text: "Visit" },
      ],
      entity: {
        id: null,
        uen_no: null,
        unit_no: null,
        name: null,
        contact_no: null,
        street_1: null,
        street_2: null,
        landmark: null,
        zip_code: null,
        gst: 0,
      },
      termsData: {
        id: null,
        title: null,
        module: null,
        validity: null,
        delivery: null,
        warranty: null,
        payment: null,
        gst_charges: null,
        deposit: null,
        cancellation: null,
        stock_availability: null,
      },
    };
  },
  methods: {
    reLoginRequired() {
      return !!+getCookie("terms_reload");
    },
    onSubmit() {
      const _this = this;

      if (!_this.$refs.termsForm.validate()) {
        return false;
      }

      let reqType = POST;
      let reqURL = "setting/quote-terms";

      if (_this.termsData.id) {
        reqType = PATCH;
        reqURL = `setting/quote-terms/${_this.termsData.id}`;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(reqType, { url: reqURL, data: _this.termsData })
        .then(({ data }) => {
          _this.terms = data;
          _this.terms_dialog = false;
          setCookie({ key: "terms_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateStatus(id) {
      const _this = this;
      _this.statusLoading = true;
      _this.$store
        .dispatch(PATCH, { url: `setting/quote-terms/${id}/status` })
        .then(({ data }) => {
          _this.terms = data;
          setCookie({ key: "terms_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.statusLoading = false;
        });
    },
    updateDefault(id) {
      const _this = this;
      _this.statusLoading = true;
      _this.$store
        .dispatch(PATCH, { url: `setting/quote-terms/${id}/make-default` })
        .then(({ data }) => {
          _this.terms = data;
          setCookie({ key: "terms_reload", value: 1 });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.statusLoading = false;
        });
    },
    addTerms() {
      this.termsData = new Object({
        id: null,
        title: null,
        module: null,
        validity: null,
        delivery: null,
        warranty: null,
        payment: null,
        gst_charges: null,
        deposit: null,
        cancellation: null,
        stock_availability: null,
      });
      this.terms_dialog = true;
    },
    updateTerms(row) {
      this.termsData = new Object({
        id: row.id,
        title: row.title,
        validity: row.validity,
        module: row.module,
        delivery: row.delivery,
        warranty: row.warranty,
        payment: row.payment,
        gst_charges: row.gst_charges,
        deposit: row.deposit,
        cancellation: row.cancellation,
        stock_availability: row.stock_availability,
      });
      this.terms_dialog = true;
    },
    getEntitySetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, { url: "setting/quote-terms" })
        .then(({ data }) => {
          _this.terms = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    editor: TinyMCE,
    ListingTable,
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.getEntitySetting();
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
