<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Ship Code
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Ship Code
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  v-on:click="updateShipCodeSetting"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-container>
            <v-row>
              <v-col md="12">
                <table class="width-100">
                  <tbody>
                    <tr v-for="(type, index) in shipcode" :key="index">
                      <td>
                        <v-text-field
                          v-model.trim="type.text"
                          dense
                          filled
                          label="Ship Code"
                          solo
                          flat
                          hide-details
                          :disabled="pageLoading"
                          :loading="pageLoading"
                          color="cyan"
                        ></v-text-field>
                      </td>
                      <td width="50" v-if="shipcode.length > 1">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="deleteShipCode(index)"
                              color="deep-orange"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-delete</v-icon
                            >
                          </template>
                          <span>Click here to delete</span>
                        </v-tooltip>
                      </td>
                      <td width="50">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-on:click="createShipCode(index)"
                              color="cyan"
                              v-bind="attrs"
                              v-on="on"
                              >mdi-plus</v-icon
                            >
                          </template>
                          <span>Click here to update</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
export default {
  name: "profile-service-setting",
  data() {
    return {
      pageLoading: true,

      shipcode: [
        {
          text: null,
        },
      ],
      imapactList: [],
    };
  },
  methods: {
    createShipCode() {
      this.shipcode.push({
        text: null,
      });
    },
    deleteShipCode(index) {
      if (this.shipcode.length > 1) {
        this.shipcode.splice(index, 1);
      }
    },
    updateShipCodeSetting() {
      const _this = this;

      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/shipcode",
          data: { shipcodeData: _this.shipcode },
        })
        .then(() => {
          /*  _this.$emit("get-report", true); */
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getShipCodeSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "ship-code/setting" })
        .then(({ data }) => {
          _this.setting = data;
          this.lodash.map(data.shipcodes, function (row, index) {
            if (_this.shipcode[index]) {
              _this.shipcode.push({
                text: row.text,
              });
            }
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getShipCodeSetting();
  },
};
</script>
