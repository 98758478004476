<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Quotation
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <QuotationSetting v-if="false"> </QuotationSetting>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <!-- <v-card flat class="custom-grey-border remove-border-radius">
      <v-card-title class="headline grey lighten-4">
            <v-row>
              <v-col md="6" class="py-0 my-auto">
                <h3 class="font-weight-700 custom-headline color-custom-blue">
                  Quotation signature for customer
                </h3>
               
              </v-col>
                  <v-col md="6" class="text-right py-0">
                    <v-btn
                      small
                      class="text-white"
                      color="cyan"
                      :disabled="pageLoading"
                      :loading="pageLoading"
                      v-on:click="updateSetting"
                    >
                      <span class="font-size-16 font-weight-600">Update</span>
                    </v-btn>
                  </v-col>
            </v-row>
          </v-card-title>
        <v-card-text class="p-6 font-size-16">
              <table class="width-100">
                <tbody>
                    <tr
                      class="custom-border-top"
                    >
                      <td
                        colspan="2"
                        class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                      >
                       <template>
                        <div class="d-flex  mb-3">
                          <p class="my-auto m-0 mr-4" style="width:200px !important"> Show Quotation signature
                            </p>
                          <p class="my-auto m-0 mx-4">
                            <v-switch
                              inset
                              color="green"
                              class="m-0"
                              hide-details
                              v-model="signature.value"
                            ></v-switch>
                          </p>
                          <div v-if="signature && signature.field" style="height: 200px; width: 1013px;"  class="cursor-pointer">
                                <v-img
                                  contain
                                  style="height: 200px;"
                                  lazy-src="https://fakeimg.pl/450x450"
                                  :src="$assetURL('media/quote-pdf/siganture.png')"
                                ></v-img>   
                          </div>
                        </div>
                      </template>
                      </td>
                    </tr>
                </tbody>
              </table>
            </v-card-text>
      </v-card>  -->
      <v-card flat class="custom-grey-border remove-border-radius">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3 class="font-weight-700 custom-headline color-custom-blue">
                PDF Setting
              </h3>
              <!-- <pre>{{emailQuotationTemplates}}</pre> -->
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-on:click="updatePdfSetting"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <table class="width-100">
            <tbody>
              <tr class="custom-border-top">
                <td
                  colspan="2"
                  class="pb-4 font-weight-600 font-size-16 color-custom-blue"
                >
                  <template v-for="(row, index) in pdf_settings">
                    <div
                      class="d-flex mb-3"
                      :key="index"
                      style="border-bottom: 1px solid lightgray"
                    >
                      <p
                        class="my-auto m-0 mr-4"
                        style="width: 200px !important"
                      >
                        <template
                          v-if="row && row.field && row.field == 'pdf-header'"
                          >Show PDF Header Every Page
                        </template>
                        <template
                          v-else-if="
                            row && row.field && row.field == 'pdf-address'
                          "
                          >Show Service Address</template
                        >
                        <template
                          v-else-if="
                            row &&
                            row.field &&
                            row.field == 'customer-reference'
                          "
                          >Show Customer Reference</template
                        >
                        <template
                          v-else-if="
                            row &&
                            row.field &&
                            row.field == 'internal-reference'
                          "
                          >Show Internal Reference</template
                        >
                        <template
                          v-else-if="row && row.field && row.field == 'sales'"
                          >Show Sales representatives</template
                        >
                        <template
                          v-else-if="
                            row && row.field && row.field == 'prepared'
                          "
                          >Show Quote by</template
                        >
                      </p>
                      <p
                        class="my-auto m-0 mx-4"
                        style="width: 60px !important"
                      >
                        <v-switch
                          inset
                          color="green"
                          class="m-0"
                          hide-details
                          v-model="row.value"
                        ></v-switch>
                      </p>
                      <div
                        v-if="row && row.field"
                        class="cursor-pointer"
                        style="flex-grow: 1 !important"
                      >
                        <img
                          contain
                          style="
                            height: 200px;
                            width: 100%;
                            object-fit: contain;
                          "
                          lazy-src="https://fakeimg.pl/450x450"
                          :src="$assetURL(getPath(row.field))"
                        />
                      </div>
                    </div>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>
      <v-card
        v-if="false"
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="6" class="pb-4 col-lg-3">
              <label>Open Days</label>
              <v-text-field
                v-model.trim="setting.quotation_open_days"
                dense
                filled
                label="Open Days"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                v-mask="'####'"
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="5" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Quotation Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="7" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateQuotationSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <editor
                v-model="setting.terms_conditions"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        flat
        class="custom-grey-border remove-border-radius mt-4"
        v-if="false"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Internal Notes
              </h3>
            </v-col>
            <v-col md="6" class="text-right py-0">
              <v-btn
                small
                class="text-white"
                color="cyan"
                v-on:click="updateQuotationSetting"
                :disabled="pageLoading"
                :loading="pageLoading"
              >
                <span class="font-size-16 font-weight-600">Update</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="p-6 font-size-16">
          <v-row>
            <v-col md="12" class="pb-4">
              <editor
                v-model="setting.client_note"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col>
            <!-- <h3
              class="col-md-12 col font-weight-700 custom-headline color-custom-blue"
              style="margin: 3.5px 0px"
            >
              Installation Clause
            </h3>
            <v-col md="12" class="pb-4">
              <editor
                v-model="setting.client_note"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, PATCH } from "@/core/services/store/request.module";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import { map, toSafeInteger } from "lodash";
import QuotationSetting from "@/view/pages/profile/Setting/Quotation-Setting";
export default {
  name: "profile-quotation-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        quotation_open_days: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
      },
      signature: {
        type: "47",
        field: "signature-link",
        value: "1",
        entity: 0,
        autoload: 0,
      },
      pdf_settings: [
        {
          type: "47",
          field: "pdf-header",
          value: "1",
          entity: 0,
          autoload: 0,
        },
      ],
      /* pdfSettings : {
        value:[ ]
      } */
    };
  },

  components: {
    editor: TinyMCE,
    QuotationSetting,
  },
  methods: {
    getPath(field) {
      if (field == "pdf-header") {
        return "media/quote-pdf/main.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/0SZEBcL7tAKhyuQVmr8jU1igxdp8sdwljE8FWyNV.png' */
      } else if (field == "pdf-address") {
        return "media/quote-pdf/addressa.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/4gyYGkjS42urEpaB1CoYkfIAgSV1iiutWMTNXxtz.png' */
      } else if (field == "customer-reference") {
        return "media/quote-pdf/customer.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/KQrVUI9Pxfyi9jh1ztE5IOOLxYz99kRZN2W3BJ2w.png' */
      } else if (field == "internal-reference") {
        return "media/quote-pdf/internal.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/PuhjiKSHwAar8jXj2GCTB3TIP6IAtKwFXjSOoUkP.png' */
      } else if (field == "sales") {
        return "media/quote-pdf/sales-by.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/QAFJ3mXY1CU4ra21zfsSGbYXWMGSqawRx82ClmUX.png' */
      } else if (field == "prepared") {
        return "media/quote-pdf/quote.png"; /* 'https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/ATYDJHIO545/files/image/png/rwVBmtJcwCC3z0L3KBTSbkVbQiTbPERxguaQbEDs.png' */
      }
    },
    /* OpenImg(url) {
      window.open(url, "_blank");
    }, */
    getQuotationSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/quotation" })
        .then(({ data }) => {
          _this.setting = data;
          /*    _this.signature = data.signature;
          _this.signature.value = toSafeInteger(data.signature.value); */
          if (data && data.pdf_settings) {
            _this.pdf_settings = data.pdf_settings;
            map(data.pdf_settings, function (row, index) {
              _this.pdf_settings[index].value = toSafeInteger(row.value);
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updatePdfSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "setting/pdf-setting",
          data: {
            pdf_settings: _this.pdf_settings,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateSetting() {
      const _this = this;
      _this.pageLoading = true;
      if (this.signature.value) {
        this.signature.value = 1;
      } else {
        this.signature.value = 0;
      }
      _this.$store
        .dispatch(PATCH, {
          url: "setting/signature-setting",
          data: this.signature,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getQuotationSetting();
  },
};
</script>
